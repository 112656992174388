import Button from 'react-bootstrap/Button';
import Container from 'react-bootstrap/Container';
import Form from 'react-bootstrap/Form';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';
import { Link } from 'react-router-dom';
import {useSelector, useDispatch} from 'react-redux';
import {useState, useEffect} from 'react';
import { isInstalled, connectWallet, balance } from '../helpers/Web3helper';
import Logo from '../img/logo.png';
// import Web3 from 'web3';
function Header() {

  const data = useSelector((state) => state.Web3);
  const dispatch = useDispatch();
  // initial state after loading
  useEffect(() => {
    console.log(data)
    if(isInstalled()){
      dispatch({type: 'WEB3_INSTALLED', installed: true});
    }

  }, [])

  // connect Wallet
  const connect = async() => {

    // const web3 = new Web3(window.ethereum);
    // await window.ethereum.enable();
    // const accounts = web3.eth.getAccounts();
    // // window.ethereum.request({ method: 'eth_requestAccounts' });
    // const account = accounts[0];
    // console.log(account);
      
      connectWallet().then((acc) => {
        console.log(data)
        dispatch({type: 'WEB3_LOGGED', logged: true});
        dispatch({type: 'WEB3_WEB3', account: acc});
        
        balance().then((bal) => {
          // console.log(bal)
          dispatch({type: 'WEB3_ACCOUNT', account: bal.address});
          dispatch({type: 'WEB3_BALANCE', balance: bal.bal});
        })
      });

      
    }
  return (
    <Navbar expand="lg" variant="light" className="bg-body-tertiary">
      <Container>
        <Navbar.Brand href="#" className="text-white">
          <img src={Logo} width="30" height="30" className="d-inline-block align-top" alt=""/>
          Forline</Navbar.Brand>
        <Navbar.Toggle aria-controls="navbarScroll" />
        <Navbar.Collapse id="navbarScroll">
          <Nav
            className="me-auto my-2 my-lg-0"
            style={{ maxHeight: '100px' }}
            navbarScroll
          >
            {/* <Nav.Link as={Link} to="/">Home</Nav.Link>
            <Nav.Link as={Link} to="/profile">Profile</Nav.Link>
            <Nav.Link as={Link} to="/team">My Team</Nav.Link>
            
            <NavDropdown title="Plans" id="navbarScrollingDropdown">
              <NavDropdown.Item as={Link} to="/working">Working</NavDropdown.Item>
              <NavDropdown.Item as={Link} to="/nonworking">
               Non Working
              </NavDropdown.Item>
            </NavDropdown>
            <Nav.Link as={Link} to="/topup">Topup</Nav.Link>
            <Nav.Link as={Link} to="/history">Reward History</Nav.Link> */}
          </Nav>
          <Form className="d-flex">
            {
              data.installed ?
              <>
                {
                  data.logged ?
                  <>
                  <Button className="btn-secondary" variant="outline-success" onClick={connect}>{data.account.slice(0, 3)} ... {data.account.slice(-3)}</Button>
                  <Button className="btn-secondary" style={{marginLeft: '10px'}} variant="outline-success">{(parseInt(data.balance) / 10 ** 18).toFixed(6)} MATIC</Button>
                  </>
                  :
                  <Button className="btn-secondary" variant="outline-success" onClick={connect}>Connect Wallet</Button>
                }
              </>:
              <Button className="btn-secondary" variant="outline-success">Install Web3 or use web3 browser</Button>
            }
            
          </Form>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
}

export default Header;