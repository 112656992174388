import Button from 'react-bootstrap/Button';
import Card from 'react-bootstrap/Card';
import ListGroup from 'react-bootstrap/ListGroup';
import * as Icon from 'react-bootstrap-icons';
import {Link} from 'react-router-dom';
import { useState } from 'react';

function NonWorkingPanel(props) {
    const [showGroup, setShow] = useState("show");
    const[treeData, setTree] = useState("hide");

    const checkActive = (active) => {
        if(props.data.active.includes(active)){
            return true;
        }
        return false;
    }

    const handleClick = (plan) => {
        setShow("hide")
        setTree("show")
    }

    const handleBack =() => {
        setShow("show")
        setTree("hide")
    }

    const TreeView = () => {
        return(
            <>
            <button className='btn btn-secondary' onClick={handleBack}>back</button>
            <ListGroup>
                <ListGroup.Item>
                    <div className='planleft'>
                        <span className="planicon iconSuccess">
                        <Icon.CheckCircle /> 
                        </span>
                        Selected Plan <span className='activePlan'>G5-1</span>
                    </div>
                    <div className='planright'>250 USD</div>
                </ListGroup.Item>
                <ListGroup.Item>
                    <div className='planleft'>
                        <span className="planicon iconSuccess">
                        <Icon.CheckCircle /> 
                        </span>
                        Completed Nodes <span className='activePlan'>(31)</span>
                    </div>
                    <div className='planright'>2500 USD</div>
                </ListGroup.Item>
            </ListGroup>
            <div className="tree">
                    <ul>
                        <li><a href="#">
                        <div className="treeHead">you</div>
                        <div className="treefoot">123456</div>
                        </a>
                        <ul>
                        <li>
                            <a href="#">
                            <div className="treeHead">Left</div>
                                <div className="treefoot">123456</div>
                            </a>
                            
                        </li>

                        <li>
                            <a href="#">
                            <div className="treeHead">Right</div>
                                <div className="treefoot">123456</div>
                            </a>
                            
                        </li>

                        </ul>
                        </li>
                        
                    </ul>
                </div>
            </>
        )
    }

  return (
    <Card className='text-light'>
      {/* <Card.Img variant="top" src="holder.js/100px180" /> */}
      <Card.Body>
        <Card.Title>{props.data.title}</Card.Title>
        <Card.Text>
            <div className={showGroup}>
        <ListGroup >
          {props.data.text.map(d => {
            return(
                <>
                {checkActive(d.plan) ?
                
                <ListGroup.Item onClick={() => handleClick(d.plan)}>
                    {/* <Link to={"/mynw/" + d.plan}> */}
                    <div className='planleft'>
                        
                            <span className="planicon iconSuccess">
                            <Icon.CheckCircle /> 
                            </span>
                        
                        Plan {props.data.planType}-{d.plan}
                        </div>
                    <div className='planright'>{d.price} USD</div>
                    {/* </Link> */}
                </ListGroup.Item>
                
                :
                    <ListGroup.Item>
                    <div className='planleft'>
                        
                            <span className="planicon iconError">
                            <Icon.ExclamationCircle /> 
                            </span>
                        
                        Plan {props.data.planType}-{d.plan}
                        </div>
                    <div className='planright'>{d.price} USD</div>
                </ListGroup.Item>
                }
                </>
                
            )
          })}
        </ListGroup>
            </div>

          <div className={treeData}>
            <TreeView />
          </div>

        <div>
          <p style={{marginTop:"8px"}}>
            Click on active plan to see more details
          </p>
        </div>
        </Card.Text>
        
      </Card.Body>
    </Card>
  );
}

export default NonWorkingPanel;