import Button from 'react-bootstrap/Button';
import Card from 'react-bootstrap/Card';

function HomeCards(props) {

  return (
    <Card className='text-light text-left'>
      {/* <Card.Img variant="top" src="holder.js/100px180" /> */}
      <Card.Body>
        <Card.Title>{props.data.title}</Card.Title>
        <Card.Text>
          {props.data.text} {props.data.btn}
        </Card.Text>
      </Card.Body>
    </Card>
  );
}

export default HomeCards;