import { Container, Row, Col, Card, Button } from "react-bootstrap";
import { Link, useNavigate } from 'react-router-dom';
import Logo from "../../img/logo.png";
import { useState, useEffect } from "react";
import {useSelector, useDispatch} from 'react-redux';
import { isInstalled, connectWallet, balance, checkUser } from '../../helpers/Web3helper';
import {getRequest} from '../../helpers/Pricehelper';
import constant from "../../constants/constant.json";

const Login = () => {

    const [userId, SetUser] = useState("");
    const data = useSelector((state) => state.Web3);
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const connect = async() => {
          
          connectWallet().then((acc) => {
            console.log(data)
            dispatch({type: 'WEB3_LOGGED', logged: true});
            dispatch({type: 'WEB3_WEB3', account: acc});
            
            balance().then((bal) => {
              // console.log(bal)
              dispatch({type: 'WEB3_ACCOUNT', account: bal.address});
              dispatch({type: 'WEB3_BALANCE', balance: bal.bal});
            })
          });
    
          
        }

        const aclogin = () => {
            if(data.logged){
                // checkUser(data.account, (r => {
                //     // console.log(r);
                //     if(r.referrer != "0x0000000000000000000000000000000000000000"){
                //         dispatch({type: 'Acc_login', logged: true});
                //         navigate('/profile');
                //     }else{
                //         // navigate('/profile');
                //         alert("User not registered")
                //     }
                // }))
                
                getRequest(constant.url+"userData?user="+data.account).then(r => {
                    if(!r.error){
                        dispatch({type: 'Acc_login', logged: true});
                        navigate('/profile');
                    }else{
                        alert(r.msg);
                    }
                })
            }
        }

        useEffect(() => {
            
            if(data.logged){
                
                // dispatch({type: 'Acc_login', logged: true});
                navigate('/profile');
            }
        },[])

    return(
        <Container>
            <Row>
                <Col className="allign-self-center">
                <img src={Logo} alt="logo" className="logo" width="150"/>
                </Col>
            </Row>
            <Row className="mt-5">
                <Col md={6} >
                <Card >
                
                <Card.Body className="contentLight">
                    <Card.Title>Login to your personal account</Card.Title>
                    <Card.Text>
                    To access all the functions of your personal account <br />Use Web3 login:
                    </Card.Text>
                    {
                        data.logged?
                        <Button variant="secondary" onClick={aclogin}>Login</Button> :
                        <Button variant="secondary" onClick={connect}>Connect Wallet</Button>
                    }
                    
                </Card.Body>
                </Card>
                </Col>

                <Col md={6}>
                <Card >
                
                <Card.Body className="contentLight">
                    <Card.Title>View your account</Card.Title>
                    <Card.Text>
                    <input type="text" className="form-control" value={userId} onChange={(event) => {SetUser(event.target.value)}} placeholder="ID"/>
                    </Card.Text>
                    <Button variant="secondary">View</Button>
                </Card.Body>
                </Card>
                </Col>
            </Row>
            <Row>
                <hr />
                <Col md={12} className="contentLight">
                    <a>Join if you are not yet with us:</a>    
                    <h4><Link to="/register" className="text-primary">Register in Forline</Link></h4>
                </Col>
            </Row>
        </Container>
    )
}

export default Login;