import { Container, Row, Col, Card, Button } from "react-bootstrap";
import {Web3} from 'web3';
const Test = () => {

    const abi = [
        {
            "inputs": [
                {
                    "internalType": "address[]",
                    "name": "ad",
                    "type": "address[]"
                },
                {
                    "internalType": "uint256[]",
                    "name": "am",
                    "type": "uint256[]"
                }
            ],
            "name": "registration",
            "outputs": [],
            "stateMutability": "payable",
            "type": "function"
        },
        {
            "inputs": [],
            "stateMutability": "nonpayable",
            "type": "constructor"
        },
        {
            "inputs": [
                {
                    "internalType": "string",
                    "name": "",
                    "type": "string"
                }
            ],
            "name": "register",
            "outputs": [
                {
                    "internalType": "bool",
                    "name": "",
                    "type": "bool"
                }
            ],
            "stateMutability": "view",
            "type": "function"
        },
        {
            "inputs": [
                {
                    "internalType": "uint256",
                    "name": "",
                    "type": "uint256"
                }
            ],
            "name": "strd",
            "outputs": [
                {
                    "internalType": "address",
                    "name": "",
                    "type": "address"
                }
            ],
            "stateMutability": "view",
            "type": "function"
        }
    ];
    const add = "0x39d3add1dDb96aB835EE44303969Fb9Af757d2F0";
    const clk = async() => {
        const web3 = new Web3(window.ethereum);
        const accounts = await window.ethereum.request({ method: 'eth_requestAccounts' });
        const account = accounts[0];
        
        const myContract = new web3.eth.Contract(abi, add);
        // console.log(myContract);
        const address = ['0x39fd8f659f844755215316df2e5437af40da62f6', '0x39fd8f659f844755215316df2e5437af40da62f6', '0xf61Db53C4ea33eb66CF35d959E5eA63060cD0E28'];
        const amn = ['90000000000000000', '20000000000000000', '80000000000000000'];
        console.log(address);
        console.log(amn);
        myContract.methods.registration(address, amn).send({from: account, value: "190000000000000000", gas:3000000, gasPrice: 499000000000}).then( r=> {
            console.log(r); // ["first", "second", "third"]
            // ["0x9Ac08e9814a40322B01dC95d85c5300daa2B5284","0x9cBf1235b4048Efc65Edc7023a3Fcc6b078028b3"]
        })
    }
    return (
        <div>
            <h1><Button onClick={clk}>Test</Button></h1>
        </div>
    )
}
export default Test;