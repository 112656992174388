import {Card, Button} from 'react-bootstrap';
import * as Icon from 'react-bootstrap-icons';
import { useState, useEffect } from 'react';
import { checkS3, checkS3users, s3counts } from '../../helpers/Web3helper';
import {useSelector} from 'react-redux';

const G5 = (props) => {
    const data = useSelector((state) => state.Web3);
    const [s3data, setS3] = useState(); 
    const [loading, setLoading] = useState(false);
    const [usercounts, setcounts] = useState(0);
    useEffect(() => {
        // checkS3(data.account, (props.data.plan - 1), ( r => {
        //     // console.log(r);
        //     setS3(r);
        //     // console.log(parseInt(s3data.cycles))
        //     setLoading(false);
        // }))
        // s3counts(data.account, (props.data.plan - 1), (r => {
        //     setcounts(r);
        //     console.log(r);
        // }))
        // checkS3users(data.account, (props.data.plan - 1), (r => {
        //     console.log(r)
        // }))
    }, []);
    // console.log(props)
    return(
        <>
        {
            props.data != undefined?
            <Card className='text-light' key={props.plan.plan}>
            <Card.Header>
                <div className="forsagetitleleft">{props.plan.plan}</div>
                <div className="forsageheadtitle">{props.plan.price} USD</div>
            </Card.Header>
            <Card.Body style={{paddingTop:"0px"}}>
                {
                    loading ?
                    <>Loading...</>:
                    <>
                        {
                    parseInt(props.data.user) == 0?
                    <Card.Text>
                        <div className='forexbok'>
                            <Icon.Cart />
                            <a>Activate</a>
                        </div>
                    </Card.Text>:
                    <Card.Text>
                    {
                        loading ?
                        <>Loading...</>
                        :
                        <div class="ternary-children__list">
                    {
                        props.data.left == "0" ?
                        
                        <a href="/page/x3/1/" class="ternary-children__item matrix-children__nonactive "></a>
                            :
                            <a href="/page/x3/1/" class="ternary-children__item matrix-children__active ">{props.data.left}</a>
                    }
                
                {
                        props.data.right == "0" ?
                        <a href="/page/x3/1/" class="ternary-children__item matrix-children__nonactive "></a>
                            :
                            <a href="/page/x3/1/" class="ternary-children__item matrix-children__active ">{props.data.right}</a>
                    }
                {/* <a href="/page/x3/1/" class="ternary-children__item matrix-children__nonactive ">
                </a> */}
                </div>
                    }
                
                </Card.Text>
                }
                    </>
                }

                
                

            </Card.Body>
            <Card.Footer>
                <div className="forsagetitleleft"><a>{loading? <></>:<>0</>}<Icon.PeopleFill /></a></div>
                {/* <div className="forsageheadtitleright"><a>{loading ?<></>: <>0</>} <Icon.ArrowClockwise /></a></div> */}
            </Card.Footer>
        </Card>
            :<></>
        }
        </>
        
    )
}

export default G5;