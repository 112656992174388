const initialState = {
    installed: false,
    logged: false,
    account: "",
    balance: 0,
    web3: null,
    login: false,
}

const Web3 = (state = initialState, action) => {
    switch (action.type) {
        case 'WEB3_INSTALLED':
            return {
                ...state,
                installed: action.installed
            }
        case 'WEB3_LOGGED':
            return {
                ...state,
                logged: action.logged
            }
        case 'WEB3_BALANCE':
            return {
                ...state,
                balance: action.balance
            }
        case 'WEB3_WEB3':
            return {
                ...state,
                web3: action.web3
            }
        case 'WEB3_ACCOUNT':
            return {
                ...state,
                account: action.account
            }
        case 'Acc_login':
            return {
                ...state,
                login: action.login
            }
        default:
            return state
    }
}

export default Web3;