import Button from 'react-bootstrap/Button';
import Card from 'react-bootstrap/Card';
import * as Icon from 'react-bootstrap-icons';
import { useRef } from 'react';
function HomeCards(props) {

  const textInputRef = useRef(null);
  const handleCopy = () => {
    // Select the text
    textInputRef.current.select();
    // Copy the text to the clipboard
    navigator.clipboard.writeText(props.data.link);
    alert('Copied');
};

  return (
    <Card className='text-light'>
      {/* <Card.Img variant="top" src="holder.js/100px180" /> */}
      <Card.Body>
        
        <Card.Text>
          <input type="text" className="form-control" ref={textInputRef} value={props.data.link} disabled/>
          
        </Card.Text>
        <Button variant="secondary" onClick={handleCopy}><Icon.Clipboard /> Copy</Button>
      </Card.Body>
    </Card>
  );
}

export default HomeCards;