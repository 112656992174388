import Button from 'react-bootstrap/Button';
import Card from 'react-bootstrap/Card';
import ListGroup from 'react-bootstrap/ListGroup';
import * as Icon from 'react-bootstrap-icons';

function WorkingDiagram(props) {

    const checkActive = (active) => {
        if(props.data.active.includes(active)){
            return true;
        }
        return false;
    }

  return (
    <Card className='text-light'>
      {/* <Card.Img variant="top" src="holder.js/100px180" /> */}
      <Card.Body>
        <Card.Title>{props.data.title}</Card.Title>
        <Card.Text>
        <ListGroup>
          {props.data.text.map(d => {
            return(
                <>
                    {checkActive(d.plan) ?
                        <ListGroup.Item>
                    <div className='planleft'>
                        
                        <span className="planicon iconSuccess">
                            <Icon.CheckCircle /> 
                        </span>              
                        Active Plan <span className='activePlan'>{props.data.planType}-{d.plan}</span>
                        </div>
                    <div className='planright'>{d.price} USD</div>
                    </ListGroup.Item>:
                            <></>
            }
            
                </>
            )
          })}
          <ListGroup.Item>
                <div className='planleft'>
                    <span className="planicon iconSuccess">
                        <Icon.CheckCircle /> 
                    </span>
                    Completed Cycles <span className='activePlan'>(2)</span>
                </div>
                <div className='planright'>Total 250 USD</div>
            </ListGroup.Item>
        </ListGroup>
        <div>
        <div className="tree">
	<ul>
        <li><a href="#">
        <div className="treeHead">your Sponsor</div>
        <div className="treefoot">123456</div>
        </a>
          <ul>
          <li>
			<a href="#">
            <div className="treeHead">You</div>
                <div className="treefoot">123456</div>
            </a>
			<ul>
				<li><a href="#">
                <div className="treeHead">Left</div>
                <div className="treefoot">123456</div>    
                </a></li>
				<li><a href="#">
                <div className="treeHead">Right</div>
                <div className="treefoot">123456</div>    
                </a></li>
				<li><a href="#">
                <div className="treeHead">Update Leg</div>
                <div className="treefoot">Open</div>    
                </a></li>
			</ul>
		</li>
          </ul>
        </li>
		
	</ul>
</div>
        </div>

        <div>
            <p>S3 tree data will change on based on your plan and cycle</p>
        </div>
        </Card.Text>
        
      </Card.Body>
    </Card>
  );
}

export default WorkingDiagram;