import Button from 'react-bootstrap/Button';
import Card from 'react-bootstrap/Card';
import ListGroup from 'react-bootstrap/ListGroup';
import * as Icon from 'react-bootstrap-icons';

function PlanComponent(props) {

    const checkActive = (active) => {
        if(props.data.active.includes(active)){
            return true;
        }
        return false;
    }

  return (
    <Card className='text-light'>
      {/* <Card.Img variant="top" src="holder.js/100px180" /> */}
      <Card.Body>
        <Card.Title>{props.data.title}</Card.Title>
        <Card.Text>
        <ListGroup>
          {props.data.text.map(d => {
            return(
                <ListGroup.Item>
                    <div className='planleft'>
                        {checkActive(d.plan) ?
                            <span className="planicon iconSuccess">
                            <Icon.CheckCircle /> 
                            </span>:
                            <span className="planicon iconError">
                            <Icon.ExclamationCircle /> 
                            </span>
                        }
                        
                        Plan {props.data.planType}-{d.plan}
                        </div>
                    <div className='planright'>{d.price} USD</div>
                </ListGroup.Item>
            )
          })}
        </ListGroup>
        <div>
          <p style={{marginTop:"8px"}}>
            You can view your plan details in plans menu
          </p>
        </div>
        </Card.Text>
        
      </Card.Body>
    </Card>
  );
}

export default PlanComponent;