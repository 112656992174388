// import logo from './logo.svg';
import './App.scss';
// import 'bootstrap/dist/css/bootstrap.min.css';
import Header from './containers/Header';
import { BrowserRouter as Router,Routes, Route, Link } from 'react-router-dom';
import Home from './containers/Home/Home';
import Team from './containers/team/Team';
import Topup from './containers/topup/Topup';
import History from './containers/History/History';
import Profile from './containers/profile/Profile';
import Footer from './containers/Footer';
import Login from './containers/auth/Login';
import Register from './containers/auth/Register';
import Test from './containers/Test';

function App() {
  return (
    <div className="App">
      <Router>
        <Header />
        <Routes>
          <Route exact path='/' element={<Login />}></Route>
          <Route exact path='/team' element={<Team />}></Route>
          <Route exact path='/topup' element={<Topup />}></Route>
          <Route exact path='/history' element={<History />}></Route>
          <Route exact path='/profile' element={<Profile />}></Route>
          <Route exact path='/login' element={<Login />}></Route>
          <Route exact path='/register' element={<Register />}></Route>
          <Route path='/register/:id' element={<Register />}></Route>
          <Route exact path='/test' element={<Test />}></Route>
        </Routes>
        <Footer />
      </Router>
    </div>
  );
}

export default App;
