import Button from 'react-bootstrap/Button';
import Card from 'react-bootstrap/Card';
import Form from 'react-bootstrap/Form';
import * as Icon from 'react-bootstrap-icons';
function TopupData(props) {

  return (
    <Card className='text-light'>
      {/* <Card.Img variant="top" src="holder.js/100px180" /> */}
      <Card.Body>
        <Card.Title>Upgrade your plan</Card.Title>
        <Card.Text>
        <Form>
            <fieldset>
                
                <Form.Group className="mb-3">
                <Form.Label>Select Type</Form.Label>
                <Form.Select>
                    <option value="S3">S3</option>
                    <option value="G5">G5</option>
                </Form.Select>
                </Form.Group>

                <Form.Group className="mb-3">
                <Form.Label>Select Plan</Form.Label>
                <Form.Select>
                    <option value="S3">S3</option>
                    <option value="G5">G5</option>
                </Form.Select>
                </Form.Group>
                
            </fieldset>
            </Form>
          
        </Card.Text>
        <Button variant="secondary"><Icon.CashCoin /> Topup</Button>
      </Card.Body>
    </Card>
  );
}

export default TopupData;