import { Container, Row, Col, Card, Button } from "react-bootstrap";
import { Link, useNavigate} from "react-router-dom";
import Logo from "../../img/logo.png";
import { useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import {useSelector, useDispatch} from 'react-redux';
import {checkId, approveUsdt, connectWallet, registerUser, balance} from '../../helpers/Web3helper';
import { getUsdtMaticPrice, getRequest } from "../../helpers/Pricehelper";
import {Web3} from 'web3';

const Register = () => {

    const [userId, SetUser] = useState("");
    const [usd, setUsd] = useState(0);
    const [regs, setRegs] = useState(false);
    const data = useSelector((state) => state.Web3);
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const {id} = useParams();

    useEffect(() => {
        // console.log(id);
        getUsdtMaticPrice().then(r => {
            setUsd(r);
        });
        if(id){
            SetUser(id);
        }
    })

    const connect = async() => {
          
        connectWallet().then((acc) => {
          console.log(data)
          dispatch({type: 'WEB3_LOGGED', logged: true});
          dispatch({type: 'WEB3_WEB3', account: acc});
          
          balance().then((bal) => {
            // console.log(bal)
            dispatch({type: 'WEB3_ACCOUNT', account: bal.address});
            dispatch({type: 'WEB3_BALANCE', balance: bal.bal});
          })
        });
  
        
      }

    const abi = [
        {
            "inputs": [
                {
                    "internalType": "address[]",
                    "name": "ad",
                    "type": "address[]"
                },
                {
                    "internalType": "uint256[]",
                    "name": "am",
                    "type": "uint256[]"
                }
            ],
            "name": "registration",
            "outputs": [],
            "stateMutability": "payable",
            "type": "function"
        },
        {
            "inputs": [],
            "stateMutability": "nonpayable",
            "type": "constructor"
        },
        {
            "inputs": [
                {
                    "internalType": "string",
                    "name": "",
                    "type": "string"
                }
            ],
            "name": "register",
            "outputs": [
                {
                    "internalType": "bool",
                    "name": "",
                    "type": "bool"
                }
            ],
            "stateMutability": "view",
            "type": "function"
        },
        {
            "inputs": [
                {
                    "internalType": "uint256",
                    "name": "",
                    "type": "uint256"
                }
            ],
            "name": "strd",
            "outputs": [
                {
                    "internalType": "address",
                    "name": "",
                    "type": "address"
                }
            ],
            "stateMutability": "view",
            "type": "function"
        }
    ];
    const add = "0x39d3add1dDb96aB835EE44303969Fb9Af757d2F0";

    const register = () => {
        setRegs(true);
        // checkId(userId, (r => {
        //     if(r == "0x0000000000000000000000000000000000000000"){
        //         alert("user not registered");
        //     }else{
              
        //             registerUser(r, data.account, (usd), (frd => {
        //                 alert(frd);
        //                 // navigate('/profile');
        //                 console.log(frd);
        //             }))
                
        //     }
        // }))
        let url = `https://forline.io/api/user/adduser/?user=${data.account}&reff=${userId}`;
        const amfu = usd * 100;
        let s3am = amfu / 2;
        let g5am = (amfu / 2) / 5;
        s3am = s3am.toFixed(6);
        g5am = g5am.toFixed(6);
        console.log(s3am);
        console.log(g5am);
        const web3 = new Web3(window.ethereum);
        getRequest(url).then(r => {
            if(!r.error){
            let ar = [];
            let am = [];
            let fam = 0;
            ar.push(r.s3.toLowerCase());
            for(let a in r.g5){
                console.log(a);
                ar.push(r.g5[a].toLowerCase());
            }
            if(r.g5.length < 5){
                ar.push("0xf61Db53C4ea33eb66CF35d959E5eA63060cD0E28");
            }
            am.push(web3.utils.toWei(s3am.toString(), 'ether'));
            fam += parseInt(web3.utils.toWei(s3am.toString(), 'ether'));
            for(let i = 0; i < r.g5.length; i++){
                am.push(web3.utils.toWei(g5am, 'ether'));
                fam += parseInt(web3.utils.toWei(g5am, 'ether'));
            }
            am.push(web3.utils.toWei(((5-r.g5.length) * g5am), 'ether'));
            fam += parseInt(web3.utils.toWei(((5-r.g5.length) * g5am), 'ether'));


            
            window.ethereum.request({ method: 'eth_requestAccounts' }).then( accounts => {
            const account = accounts[0];
        
        const myContract = new web3.eth.Contract(abi, add);
        // console.log(myContract);
        const address = ar;
        const amn = am;
        console.log(address);
        console.log(amn);
        console.log(fam);
        myContract.methods.registration(address, amn).send({from: account, value: fam.toString(), gas:3000000, gasPrice: 609900000000})
        .on('receipt', function(receipt){
            getRequest("https://forline.io/api/user/updatereg?tx="+receipt.transactionHash+"&user="+data.account).then(r => {
            if(!r.error){
            alert("Registration Successful");
            setRegs(false);
            navigate('/profile');
            }else{
                alert("Registration Failed");
                // setRegs(false);
                navigate('/register');
            }
            })
        })
        .on('error', function(error){
            alert("Registration Failed");
            // setRegs(false);
            navigate('/profile');
        }).then( r=> {
            console.log(r); // ["first", "second", "third"]
            // ["0x9Ac08e9814a40322B01dC95d85c5300daa2B5284","0x9cBf1235b4048Efc65Edc7023a3Fcc6b078028b3"]
        })
        })
        // setRegs(false);
        }else{
            alert(r.msg);
            setRegs(false);
        }
            
        })
        
        
    }
    return(
        <Container>
            <Row>
                <Col className="allign-self-center">
                <img src={Logo} alt="logo" className="logo" width="150"/>
                </Col>
            </Row>
            <Row className="mt-5">
                <Col md={6} >
                <Card >
                
                <Card.Body className="contentLight">
                    <Card.Title>Register With Forline</Card.Title>
                    <Card.Text>
                        <a>Check the ID of your sponsor</a> <br />
                        <a>{usd == 0 ? "Loading..." : `You need to pay: ${100 * usd} MATIC`}</a>
                        <hr />
                        {
                            id?<input type="text" className="form-control" value={id} placeholder="Spondor ID" disabled/>
                            :<input type="text" className="form-control" value={userId} onChange={(event) => {SetUser(event.target.value)}} placeholder="Spondor ID"/>
                        }
                        
                    </Card.Text>
                    {data.account != ""? 
                    regs ? <Button variant="secondary" disabled>Wait...</Button> : <Button variant="secondary" onClick={register}>Register</Button> : <Button variant="secondary" onClick={connect}>Connect wallet</Button>}
                    {/* <Button variant="secondary" onClick={register}>Register</Button> */}
                </Card.Body>
                </Card>
                </Col>

                <Col md={6}>
                <Card >
                
                <Card.Body className="contentLight">
                    <Card.Title>Selected Network</Card.Title>
                    <Card.Text>
                    Polygon Mainnet
                    <hr />
                    <a>The current Polygon network Load: Normal</a>
                    </Card.Text>
                    <Button variant="secondary">Change Network</Button>
                </Card.Body>
                </Card>
                </Col>
            </Row>
            <Row>
                <hr />
                <Col md={12} className="contentLight">
                    <a>Join if you are not yet with us:</a>    
                    <h4><Link to="/login" className="text-primary">Login to Forline</Link></h4>
                </Col>
            </Row>
        </Container>
    )
}

export default Register;