import Button from 'react-bootstrap/Button';
import Card from 'react-bootstrap/Card';
import Table from 'react-bootstrap/Table';
import * as Icon from 'react-bootstrap-icons';
function HistoryData(props) {

  return (
    <Card className='text-light'>
      {/* <Card.Img variant="top" src="holder.js/100px180" /> */}
      <Card.Body>
        <Card.Title>Reward History</Card.Title>
        <Card.Text>
          
          
        </Card.Text>

        <Table striped bordered hover>
            <thead>
                <tr>
                <th>#</th>
                <th>Hash</th>
                <th>Reward Type</th>
                <th>Amount</th>
                <th>Reward From</th>
                <th>Reward For</th>
                <th>Time</th>
                </tr>
            </thead>
            <tbody>
                <tr><td colSpan={7}>No Data</td></tr>
            </tbody>
    </Table>
        
      </Card.Body>
    </Card>
  );
}

export default HistoryData;