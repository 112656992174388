
import HomeCards from "../../components/cards/HomeCards";
import {Col, Row,Container} from "react-bootstrap";
import plans from '../../constants/plans.json';
import PlanComponent from "../../components/cards/PlanComponent";
import PersonalComponent from "../../components/cards/PersonalComponent";
import Copy from "../../components/cards/Copy";
import TopupData from "../../components/cards/Topup";
import HistoryData from "../../components/tables/History";


const History = () => {

    return(
        <Container>
        <Row className="mt-5">

            <Col md={3}>
                <HomeCards data={{title:"Total Income", text:"Total Income in all plans \n", btn:"0 USD"}}/>
            </Col>
            <Col md={3}>
                <HomeCards data={{title:"S3 Income", text:"Total income in S3 plan \n", btn:"0 USD"}}/>
            </Col> 
            <Col md={3}>
                <HomeCards data={{title:"G5 Income", text:"Total income in G5 plan \n", btn:"0 USD"}}/>
            </Col> 
            <Col md={3}>
                <HomeCards data={{title:"Direct Members", text:"Total Direct Members \n", btn:"0"}}/>
            </Col>  
        </Row>

        <Row className="mt-3">
            <Col md={9}>
                <Row>
                    <Col>
                    <HistoryData />
                    </Col>
                    
                </Row>
            </Col>
            <Col md={3}>
                <Copy data={{link:"https://www.google.com/register/124587"}} />
                <hr />
            <PersonalComponent data={{title:"Total Members", text:"Total Global Members \n", btn:"0"}}/>
            <hr />
            <PersonalComponent data={{title:"Total Deposits", text:"Total USD Deposited \n", btn:"0"}}/>
            <hr />
            <PersonalComponent data={{title:"Total Profits", text:"Total Profits in USD \n", btn:"0"}}/>
            
            </Col>
        </Row>
        </Container>
    );
}

export default History;