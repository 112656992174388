import {Web3} from 'web3';
import abi from '../constants/contract.json';
import constant from '../constants/constant.json';
import usdt from '../constants/usdt.json';

const isInstalled = () =>{
    if(window.ethereum){
        return true;
    }else{
        return false;
    }
}

const connectWallet = async () =>{
    console.log("connect wallet function");
    const web3 = new Web3(window.ethereum);
    await window.ethereum.enable();
    // const accounts = await window.ethereum.request({ method: 'eth_requestAccounts' });
    // const account = accounts[0];
    return web3;
}

const balance = async () =>{
    const web3 = new Web3(window.ethereum);
    const accounts = await window.ethereum.request({ method: 'eth_requestAccounts' });
    const account = accounts[0];
    const balance = await web3.eth.getBalance(account);
    return {bal: balance, address: account};
}

const checkUser = (address, callback) => {
    // console.log(address)
    const web3 = new Web3(window.ethereum);
    const myContract = new web3.eth.Contract(abi, constant.contract);
    myContract.methods.users(0,address).call().then(r => {
        // console.log(r);
        callback(r);
    })
}

const checkS3 = (address, plan, callback) => {
    const web3 = new Web3(window.ethereum);
    const myContract = new web3.eth.Contract(abi, constant.contract);
    myContract.methods.S3ActiveCycle(address, plan).call().then(r =>{
        myContract.methods.S3(address, plan, parseInt(r)).call().then(rs => {
            callback({data: rs, cycles: r});
        })
    })
}

const checkS3users = (address, plan, callback) => {
    const web3 = new Web3(window.ethereum);
    const myContract = new web3.eth.Contract(abi, constant.contract);
    myContract.methods.S3ActiveCycle(address, plan).call().then(r =>{
        console.log(r)
        myContract.methods.workingTransactions(address, plan, parseInt(r)).call().then(rs => {
            callback(rs);
        })
    })
}

const checkAddress = async() =>{
    const web3 = new Web3(window.ethereum);
    const myContract = new web3.eth.Contract(abi, constant.contract);
    const accounts = await window.ethereum.request({ method: 'eth_requestAccounts' });
    const account = accounts[0];
    const r = await myContract.methods.userAddress(account).call();
    return r;
}

const checkId = (id, callback) => {
    const web3 = new Web3(window.ethereum);
    const myContract = new web3.eth.Contract(abi, constant.contract);
    myContract.methods.userAddress(id).call().then( r => {
        callback(r);
    })
}

const approveUsdt = (address, callback) => {
    const web3 = new Web3(window.ethereum);
    const myContract = new web3.eth.Contract(usdt, constant.tokencontract);
    myContract.methods.approve(constant.contract, '10000000000000000000000').send({from: address} ).then( r => {
        callback(r)
    })
}

const registerUser = (ref, address, amount, callback) => {
    const web3 = new Web3(window.ethereum);
    console.log(web3.utils.toWei(amount, 'ether'));
    const myContract = new web3.eth.Contract(abi, constant.contract);
    
    myContract.methods.register(ref).send({from: address, value: web3.utils.toWei(amount.toString(), 'ether'), gas: 4000000}).then( r=> {
        callback(r);
    })
}

const getEarnings = (address, callback) => {
    const web3 = new Web3(window.ethereum);
    const myContract = new web3.eth.Contract(abi, constant.contract);
    myContract.methods.G5income(address).call().then( r => {
        myContract.methods.S3income(address).call().then( rs => {
            callback({"g5income": r, "s3income": rs});
        })
    })
}

const gettotalMembers = (callback) => {
    const web3 = new Web3(window.ethereum);
    const myContract = new web3.eth.Contract(abi, constant.contract);
    myContract.methods.id().call().then( r => {
       callback(r);
    })
}

const s3counts = (address, plan, callback) => {
    const web3 = new Web3(window.ethereum);
    const myContract = new web3.eth.Contract(abi, constant.contract);

    myContract.methods.S3ActiveCycle(address, plan).call().then(tr => {
        myContract.methods.S3count(address, plan, tr).call().then( r => {
            callback(r);
         })
    })
    
}


export {s3counts, gettotalMembers, getEarnings, registerUser, isInstalled, connectWallet, balance, checkUser, checkS3, checkS3users, checkAddress, checkId, approveUsdt};