// const axios = require('axios');
import axios from 'axios';
const getUsdtMaticPrice = async() => {
    try {
        // Fetch the price data from CoinGecko API
        const response = await axios.get('https://min-api.cryptocompare.com/data/price?fsym=MATIC&tsyms=USDT&api_key=3d7464f90e71f255ac0ef73d31d67cd5dfccb5310645d4452424cf6a3b9369c8', {
            
        });

        const prices = response.data;
        // console.log(prices);

        return (1 / prices.USDT);
    } catch (error) {
        return false;
    }
}

const getRequest = async(url) => {
    try {
        const response = await axios.get(url);
        return response.data;
    } catch (error) {
        return false;
    }
}

export { getUsdtMaticPrice, getRequest }