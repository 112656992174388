import HomeCards from "../../components/cards/HomeCards";
import {Col, Row,Container} from "react-bootstrap";
import plans from '../../constants/plans.json';
import PlanComponent from "../../components/cards/PlanComponent";
import PersonalComponent from "../../components/cards/PersonalComponent";
import WorkingDiagram from "../../components/cards/WorkingDiagram";
import NonWorkingPanel from "../../components/cards/NonworkingPanel";
import Forsage from "../../components/cards/Forsage";
import G5 from "../../components/cards/G5";
import Copy from "../../components/cards/Copy";
import { useState, useEffect } from "react";
import {checkAddress, getEarnings, gettotalMembers} from "../../helpers/Web3helper";
import {useSelector} from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { getRequest } from "../../helpers/Pricehelper";
import constant from "../../constants/constant.json";

const Profile = () => {
    const data = useSelector((state) => state.Web3);
    const[dt, setDt] = useState();
    const[loading, setLoad] = useState(true);
    const[earnings, setEarnings] = useState(false);
    const[totmem, settot] = useState();
    const navidate = useNavigate();
    useEffect(() => {

            // if(data.account == ""){
            //     navidate('/');
            // }
        
            // checkAddress().then( r => {
            //     // console.log(r);
            //     setDt(parseInt(r));
            //     setLoad(false);
            // })
            // getEarnings(data.account, (r =>{
            //     setEarnings(r);
            //     // console.log(r);
            // }))
            // gettotalMembers((r =>{
            //     console.log(r);
            //     settot(r);
            // }))

        getRequest(constant.url+"userData?user="+data.account).then(r => {
            if(!r.error){
                setDt(r);
                console.log(r);
                setLoad(false);
            }
        })
        
        
    },[])
    return(
        <Container>
        <Row className="mt-5">

            <Col md={3}>
                <HomeCards data={{title:"Total Income", text:"Total Income in all plans \n", btn:`0 USD`}}/>
            </Col>
            <Col md={3}>
                <HomeCards data={{title:"S3 Income", text:"Total income in S3 plan \n", btn:`0 USD`}}/>
            </Col> 
            <Col md={3}>
                <HomeCards data={{title:"G5 Income", text:"Total income in G5 plan \n", btn:`0 USD`}}/>
            </Col> 
            <Col md={3}>
                <HomeCards data={{title:"S4 Income", text:"Total income in S4 plan \n", btn:"0 USD"}}/>
            </Col>  
        </Row>

        <Row className="mt-3">
            <Col md={9} className="planHolder">
                <Row>
                    <Col md={12} sm={12}>
                        <h3 style={{float:"left",color:"#fff"}}>S3 Plan</h3>
                    </Col>
                    <Col md={12} sm={12} className="displayCOntent">
                    {!loading?<>
                    {
                        plans.map((plan) => {
                            // {console.log(dt.s3[plan.plan - 1]);}
                            return(
                                <Col md={2} className="forS3">
                                <Forsage data={dt.s3[plan.plan - 1]} plan={plan}/>
                            </Col>
                            )
                        })}
                    </>:<></>}
                    
                    </Col>
                    
                   
                </Row>

                {/* <Row>
                    <Col md={12} sm={12}>
                        <h3 style={{float:"left",color:"#fff"}}>S4 Plan</h3>
                    </Col>
                    <Col md={12} sm={12} className="displayCOntent">
                    {
                        plans.map((plan) => {
                            return(
                                <Col md={2}>
                                <Forsage data={plan}/>
                            </Col>
                            )
                        })
                    }
                    </Col>
                    
                   
                </Row> */}

                <Row>
                    <Col md={12} sm={12}>
                        <h3 style={{float:"left",color:"#fff"}}>G5 Plan</h3>
                    </Col>
                    <Col md={12} sm={12} className="displayCOntent">
                    {!loading?<>
                    {
                        plans.map((plan) => {
                            // {console.log(dt.s3[plan.plan - 1]);}
                            return(
                                <Col md={2} className="forS3">
                                <G5 data={dt.g5[plan.plan - 1]} plan={plan}/>
                            </Col>
                            )
                        })}
                    </>:<></>}
                    </Col>
                    
                   
                </Row>

            </Col>
            <Col md={3}>
                {
                    loading ?
                    <>loading...</> :
                    <Copy data={{link:`https://app.forline.io/register/${dt.user[0].uid}`}} />
                }
                
                <hr />
                <PersonalComponent data={{title:"Direct Members", text:"Total Direct Members \n", btn: `0`}}/> <hr />
            <PersonalComponent data={{title:"Total Members", text:"Total Global Members \n", btn: `${totmem}`}}/>
            <hr />
            <PersonalComponent data={{title:"Total Deposits", text:"Total USD Deposited \n", btn:"0"}}/>
            <hr />
            <PersonalComponent data={{title:"Total Profits", text:"Total Profits in USD \n", btn:"0"}}/>
            
            </Col>
        </Row>
        </Container>
    );
}

export default Profile;