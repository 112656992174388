import { Container, Row, Col } from 'react-bootstrap';

const Footer = () => {
    return(
        <footer className="bg-dark footerMe text-light mt-5">
      <Container className="py-5">
        <Row>
          <Col md={4}>
            <h5>About Us</h5>
            <p>Learn more about our platform and mission.</p>
          </Col>
          <Col md={4}>
            <h5>Quick Links</h5>
            <ul className="list-unstyled">
              <li><a href="#">Home</a></li>
              <li><a href="#">FAQ</a></li>
              <li><a href="#">Contact Us</a></li>
            </ul>
          </Col>
          <Col md={4}>
            <h5>Contact Us</h5>
            <p>Email: info@forline.io</p>
            <p>Telegram: t.me/forline</p>
          </Col>
        </Row>
      </Container>
      <div className="bg-primary text-light text-center py-2">
        &copy; {new Date().getFullYear()} Forline. All rights reserved.
      </div>
    </footer>
    );
}


export default Footer;